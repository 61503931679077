<script lang="ts" setup>
const { t } = useT();
const { open } = useTaoModals();
const { data: appInitData } = useAppInitData();
const { handleSupportButtonClick } = useSupportChat();
const { playMagicAudio } = useMagicBoxAudios();
const { isActiveStatus, isFullStatus } = usePiggy({ open });
const loginGuard = useLoginGuard();
const { isSeasonQuest, data: questData } = useQuestData({ immediate: false });
const { isSpecialBox } = useSpecialMagicBox();
const { durationExceeded, promoOffer } = useExitPopup();
const { seasonInitData } = useSeasonsData({ immediate: false });
const { showPwaBanner } = usePWA();
const { tournamentExist } = useTournamentData();
const { entriesActiveTournament } = useTournamentNotification();
const { isOneClickRegister } = useOtpGetFlow(true);
const isGuest = useIsGuest();
const { isMobile } = useDevice();

const asideRef = ref();
const affiliatePartnerState = ref(false);

const props = withDefaults(
	defineProps<{
		show: boolean;
	}>(),
	{ show: false }
);

const emit = defineEmits<{ (event: "toggleSidebar"): void }>();

const toggleShow = () => {
	emit("toggleSidebar");
};

onClickOutside(asideRef, () => {
	if (!props.show) {
		return;
	}
	emit("toggleSidebar");
});

const isShowRedeem = computed(() => appInitData.value?.gameMode === "SweepStakes" && appInitData.value?.hasDeposits);
const isShowScratchCards = computed(() => !isGuest.value && appInitData.value?.scratchCardLottery?.isActive);
const isShowDepositStreak = computed(() => !isGuest.value && appInitData.value?.depositStreak?.isActive);
const isShowAffiliate = computed(() => !isGuest.value && appInitData.value?.isAffiliate);
const isShowMoneyBox = computed(() => appInitData.value?.moneyBox?.isActive && (isActiveStatus.value || isGuest.value));
const isShowPrizeDrops = computed(() => appInitData.value?.prizeDrops?.isActive);
const isShowQuests = computed(
	() => !!questData.value?.questInfo && !isSeasonQuest.value && !seasonInitData.value?.isEnabled
);
const isShowTournaments = computed(
	() =>
		tournamentExist.value ||
		(appInitData.value?.gameMode === "TournamentPoints" && appInitData.value?.isEntriesTournamentAvailable)
);
const handleRedeemClick = () =>
	loginGuard({
		success: () => {
			window?.$store?.gaCash?.deposit?.({
				location: "redeem",
				step: "payments_method"
			});
			window?.$cash?.$router?.push?.("/cash/redeem/");
		},
		fail: () => {
			if (isOneClickRegister) {
				open("LazyOModalOneClickSignup");
				return;
			}
			open("LazyOModalSignup");
		}
	});

const handleMagicBoxClick = () =>
	loginGuard({
		success: () => {
			open("LazyOModalMagicBoxes");
			playMagicAudio();

			dispatchGAEvent({
				event: "click_button",
				location: "left_menu",
				buttonName: "dailywheel"
			});
		},
		fail: () => {
			if (isOneClickRegister) {
				open("LazyOModalOneClickSignup");
				return;
			}
			open("LazyOModalSignup", { location: "sidebar_magic_box" });
		}
	});

const handlePWAClick = () =>
	loginGuard({
		success: () => {
			open("LazyOModalPwaApps", { location: "sidebar" });
		},
		fail: () => {
			open("LazyOModalSignup", { location: "sidebar_magic_box" });
		}
	});

const navigateIfAuthorized = (event: MouseEvent, location: string) =>
	loginGuard({
		fail: () => {
			if (isOneClickRegister) {
				open("LazyOModalOneClickSignup");
			} else {
				open("LazyOModalSignup", { location: `sidebar_${location}` });
			}

			event.preventDefault();
			event.stopPropagation();
		}
	});

const handleChallengeClick = () =>
	loginGuard({
		success: () => {
			open("LazyOModalChallengeSeason");

			dispatchGAEvent({
				event: "click_button",
				button_name: "winter_challenge_popup",
				location: "sidebar_menu"
			});
		},
		fail: () => {
			open("LazyOModalSignup", { location: "sidebar_challenge" });
		}
	});

const handleOpenSeason = () =>
	loginGuard({
		success: () => {
			open("LazyOModalSeasonTower");

			dispatchGAEvent({
				event: "click_button",
				button_name: "season_popup",
				location: "sidebar_menu"
			});
		},
		fail: () => {
			open("LazyOModalSignup", { location: "sidebar_season" });
		}
	});

const handlePromotionsClick = navigateIfAuthorized;
const handleQuestClick = navigateIfAuthorized;
const handlePiggyBankClick = navigateIfAuthorized;
const handleReferralClick = navigateIfAuthorized;

const handleSupportClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "left_menu",
		button_name: "support"
	});
	handleSupportButtonClick();
};

onMounted(() => {
	watch(
		() => props.show,
		(value) => {
			if (isMobile) {
				setTimeout(() => {
					document.body.classList?.[value ? "add" : "remove"]("overlay-hidden");
				}, 300);
			}
		},
		{ immediate: true }
	);
});
</script>
<template>
	<AOverlay data-tid="menu-container" :modifiers="['fixed']" :class="{ show }">
		<aside ref="asideRef">
			<NuxtLink to="/">
				<NuxtImg
					src="/nuxt-img/logo-icon@2x.png"
					alt="Tao fortune"
					data-tid="logo"
					format="webp"
					width="321"
					height="136"
					class="logo-img"
				/>
			</NuxtLink>

			<AIconButton class="close-btn" variant="ghost" @click="toggleShow">
				<NuxtIcon name="20/close" filled />
			</AIconButton>

			<div class="events">
				<OSidebarTimerOffer v-if="!durationExceeded && promoOffer" />
				<MSidebarItem
					v-if="isSeasonQuest"
					:title="t('winter Challenge')"
					title-gradient="var(--gradient-3)"
					image="/nuxt-img/challenge/snow-ball-side.png"
					:badge-title="t('New')"
					@click="handleChallengeClick"
				/>
				<NuxtLink
					v-if="isShowQuests"
					to="/quest/"
					data-tid="header-promo-quest"
					@click.capture="(e: MouseEvent) => handleQuestClick(e, 'quest')"
				>
					<MSidebarItem :title="t('tao quest')" image="/nuxt-img/sidebar/tao-quest.png" />
				</NuxtLink>
				<MSidebarItem
					v-if="seasonInitData?.isEnabled"
					class="sidebar-item-season"
					:title="t('Treasure Hunt')"
					image="/nuxt-img/seasons/hunt/sidebar-hunt.png"
					@click="handleOpenSeason"
				/>
				<MSidebarItem
					:title="isSpecialBox ? t('Special box') : t('Magic box')"
					:image="`/nuxt-img/sidebar/${isSpecialBox ? 'special' : 'magic'}-box.png`"
					data-tid="header-promo-magic-box"
					@click="handleMagicBoxClick"
				/>
				<NuxtLink v-if="!isGuest && isShowPrizeDrops" to="/prize-drops/">
					<MSidebarItem
						:title="t('prizeDrops.sidebar.title')"
						titleGradient="var(--gradient-3)"
						image="/nuxt-img/prize-drops/notify.png"
						data-tid="header-promo-prize-drops"
						:badge-title="t('New')"
					/>
				</NuxtLink>
				<NuxtLink v-if="isShowScratchCards" to="/scratch-cards/">
					<MSidebarItem :title="t('Super Scratchers')" image="/nuxt-img/sidebar/scratch-cards.png" />
				</NuxtLink>
				<MSidebarItem
					v-if="isShowDepositStreak"
					image="/nuxt-img/deposit-streak/icon-menu.webp"
					:badge-title="t('New')"
					@click="open('LazyOModalDepositStreak')"
				>
					<ODepositStreakSidebar />
				</MSidebarItem>
			</div>

			<div class="links-wrapper" data-tid="menu-links">
				<NuxtLink to="/issues/popular-games/" class="link" data-tid="header-top-games">
					<NuxtIcon name="20/crown" filled />
					<AText type="base">
						{{ t("Top games") }}
					</AText>
				</NuxtLink>
				<NuxtLink to="/issues/jackpot/" class="link" data-tid="header-jackpot">
					<NuxtIcon name="20/jackpot" filled />
					<AText type="base">
						{{ t("Jackpot games") }}
					</AText>
				</NuxtLink>
				<NuxtLink to="/issues/hold-n-link/" class="link" data-tid="header-hold-n-link">
					<NuxtIcon name="20/holdnlink" filled />
					<AText type="base">
						{{ t("Hold-n-Link games") }}
					</AText>
				</NuxtLink>
				<NuxtLink to="/issues/all-games/" class="link" data-tid="header-all-games">
					<NuxtIcon name="20/slots" filled />
					<AText type="base">
						{{ t("All games") }}
					</AText>
				</NuxtLink>
				<ADivider class="divider" />
				<NuxtLink v-if="!isGuest" to="/how-it-works/" class="link" data-tid="header-how-it-works">
					<NuxtIcon name="20/info" filled />
					<AText type="base">
						{{ t("How it works") }}
					</AText>
				</NuxtLink>
				<div v-if="isShowRedeem" class="link" data-tid="header-redeem" @click="handleRedeemClick">
					<NuxtIcon name="20/redeem" filled />
					<AText type="base">
						{{ t("Redeem") }}
					</AText>
				</div>
				<NuxtLink
					to="/promotions/"
					class="link"
					data-tid="header-promotions"
					@click.capture="(e: MouseEvent) => handlePromotionsClick(e, 'promotions')"
				>
					<NuxtIcon name="20/promotion" filled />
					<AText type="base">
						{{ t("Promotions") }}
					</AText>
					<AText type="xx-small" :modifiers="['bold']" class="counter">1</AText>
				</NuxtLink>
				<NuxtLink
					v-if="isShowMoneyBox"
					to="/piggy-bank/"
					class="link"
					data-tid="header-piggy-bank"
					@click.capture="(e: MouseEvent) => handlePiggyBankClick(e, 'piggy_bank')"
				>
					<NuxtIcon name="20/piggy-bank" filled />
					<AText type="base">
						{{ t("Piggy Bank") }}
					</AText>
					<AText v-if="isFullStatus" type="xx-small" :modifiers="['bold']" class="counter">1</AText>
				</NuxtLink>
				<NuxtLink v-if="isShowScratchCards" to="/scratch-cards/" class="link">
					<NuxtIcon name="20/scratch-cards" filled />
					<AText type="base">
						{{ t("Scratch cards") }}
					</AText>
				</NuxtLink>
				<div class="link" data-tid="header-magic-box" @click="handleMagicBoxClick">
					<NuxtIcon :name="`20/${isSpecialBox ? 'lanterns' : 'chest'}`" filled />
					<AText type="base">
						{{ isSpecialBox ? t("Magic Lanterns") : t("Magic BOX") }}
					</AText>
				</div>
				<NuxtLink
					v-if="isShowQuests"
					to="/quest/"
					class="link"
					data-tid="header-quest"
					@click.capture="(e: MouseEvent) => handleQuestClick(e, 'quest')"
				>
					<NuxtIcon name="20/quest" filled />
					<AText type="base">
						{{ t("TAO Quest") }}
					</AText>
				</NuxtLink>
				<NuxtLink
					v-if="isShowTournaments"
					to="/tournaments/"
					class="link"
					data-tid="header-tournaments"
					@click.capture="navigateIfAuthorized"
				>
					<NuxtIcon name="20/tournaments" filled />
					<AText type="base">
						{{ t("Tournaments") }}
					</AText>
					<AText v-if="entriesActiveTournament" type="xx-small" :modifiers="['bold']" class="counter">1</AText>
				</NuxtLink>
				<NuxtLink
					v-if="!isGuest"
					to="/referral/"
					class="link"
					data-tid="header-referral"
					@click.capture="(e: MouseEvent) => handleReferralClick(e, 'referral')"
				>
					<NuxtIcon name="20/referral" filled />
					<AText type="base" class="referral">
						<i18n-t keypath="Get {key1} and {key2}">
							<template #key1>
								<ASvg class="coin-icon" name="12/secret-coins" />
								<b>2,000</b>
							</template>
							<template #key2>
								<br />
								<ASvg class="coin-icon" name="12/coins" />
								<b>500,000</b>
							</template>
						</i18n-t>
					</AText>
				</NuxtLink>
				<NuxtLink v-if="!isGuest" to="/game/" data-tid="header-account" class="link">
					<NuxtIcon name="20/account" filled />
					<AText type="base">
						{{ t("Account") }}
					</AText>
				</NuxtLink>
				<template v-if="!isGuest">
					<NuxtLink v-if="!isShowAffiliate" to="/affiliates/" class="link" data-tid="header-affiliate-program">
						<NuxtIcon name="20/affiliate" filled />
						<AText type="base">
							{{ t("Affiliate System") }}
						</AText>
					</NuxtLink>

					<div v-else :class="{ 'partner-opened': affiliatePartnerState }">
						<AText class="link" type="base" @click="affiliatePartnerState = !affiliatePartnerState">
							<NuxtIcon name="20/affiliate" filled />
							{{ t("Affiliate Program") }}

							<NuxtIcon
								class="icon-arrow"
								:name="`16/${affiliatePartnerState ? 'arrow-up-small' : 'arrow-down-small'}`"
								filled
							/>
						</AText>

						<Transition name="fade">
							<div v-if="affiliatePartnerState" class="affiliate-wrapper">
								<NuxtLink to="/affiliates/partners-account" class="affiliate-link">
									<AText variant="tempe">
										{{ t("Partner’s Account") }}
									</AText>
								</NuxtLink>
							</div>
						</Transition>
					</div>
				</template>
				<div class="link" data-tid="header-support" @click="handleSupportClick">
					<NuxtIcon name="20/support" filled />
					<AText type="base">
						{{ t("Support") }}
					</AText>
				</div>
				<MSidebarItem
					v-if="showPwaBanner"
					:title="t('Get the app')"
					image="/nuxt-img/sidebar/logo-pwa.png"
					data-tid="header-pwa-banner"
					@click="handlePWAClick"
				/>
			</div>
		</aside>
	</AOverlay>
</template>
<style scoped lang="scss">
.overlay {
	z-index: 100;
	width: var(--o-sidebar-width);
	display: block;

	@include media-breakpoint-down(lg) {
		display: none;
		width: 100%;
	}

	&.show {
		display: block;
	}
}
aside {
	background-color: var(--sidebar-bg);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: var(--o-sidebar-gap-sm);
	padding: var(--o-sidebar-padding-y) var(--o-sidebar-padding-x);
	overflow: auto;

	@include scrollbar(4px, 2px, var(--color-gray-600), var(--color-gray-500), var(--color-gray-300));

	@include media-breakpoint-up(sm) {
		width: var(--o-sidebar-width);
		gap: var(--o-sidebar-gap);
	}

	a {
		display: inline-flex;
		text-decoration: none;
		width: 100%;
	}
}

.logo-img {
	width: 112px;
	height: 48px;
	margin-bottom: 8px;

	@include media-breakpoint-up(sm) {
		width: 160px;
		height: 68px;
		margin: 0 auto 16px;
	}
}

.close-btn {
	position: absolute;
	top: var(--o-sidebar-padding-y);
	right: var(--o-sidebar-padding-x);
	z-index: 9;

	.nuxt-icon:not(.icon-arrow) {
		font-size: 16px;
		line-height: 1;
		padding: 2px;

		&:deep(svg) path {
			fill: var(--neutral);
		}
	}

	@include media-breakpoint-up(sm) {
		display: none;
	}
}

.events {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 8px;

	@include media-breakpoint-up(sm) {
		flex-direction: column;
	}

	&--md-hide {
		display: none;
		width: 100%;
		gap: 8px;

		@include media-breakpoint-up(sm) {
			display: flex;
			flex-direction: column;
		}
	}
}

.links-wrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;
	cursor: default;

	@include media-breakpoint-down(sm) {
		padding: 8px;
		border-radius: 8px;
		gap: 8px;
		background-color: var(--color-gray-900);
	}
}

.link {
	display: flex;
	align-items: center;
	gap: 12px;
	position: relative;
	z-index: 1;
	cursor: pointer;
	padding: 7px 8px;
	border-radius: 4px;
	transition: opacity 0.2s ease;
	color: var(--neutral);

	&.is-disabled {
		background: var(--gray-900);
	}

	.nuxt-icon:not(.icon-arrow) {
		color: var(--secondary);
		line-height: 1;

		&:deep(svg path) {
			fill: var(--secondary);
		}
	}

	.referral {
		br {
			display: none;
		}

		.coin-icon {
			font-size: 14px;
			margin-right: 4px;
			position: relative;
			top: 1px;
		}

		@include media-breakpoint-up(sm) {
			br {
				display: block;
			}
		}
	}

	&:hover,
	&.router-link-active {
		background-color: var(--gray-800);

		@include media-breakpoint-up(sm) {
			background-color: var(--gray-900);
		}

		.nuxt-icon:not(.icon-arrow) {
			color: var(--primary);
			&:deep(svg path) {
				fill: var(--primary);
			}
		}
	}

	.counter {
		position: absolute;
		right: 8px;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background-color: var(--danger-300);
		line-height: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--neutral);
	}
}

.partner-opened {
	border-radius: 4px;
	background: var(--gray-900);

	.nuxt-icon:not(.icon-arrow) {
		color: var(--primary);

		&:deep(svg path) {
			fill: var(--primary);
		}
	}
}

.affiliate-wrapper {
	padding: 5px 8px 8px 40px;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.affiliate-link {
	text-decoration: underline;
	color: var(--primary-400);

	&:hover {
		text-decoration: none;
	}
}

.nuxt-icon {
	font-size: 20px;

	&:deep(svg) {
		transition: 0.2s ease;
	}
}

.divider {
	@include media-breakpoint-down(sm) {
		margin: 0 8px;
		width: auto;
	}
}

.sidebar-item-season:deep(.name) {
	color: var(--primary-400);
}
</style>
<style lang="scss">
body.overlay-hidden {
	overflow: hidden !important;
}
</style>
